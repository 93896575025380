import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  // Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';
import auth from '../firebase';
import { useLocation } from 'react-router-dom';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const location = useLocation();
  const [projectId, setProjectId] = useState('sample data')

  useEffect(() => {
    setProjectId(location.pathname.includes('project/') ? location.pathname.split('/').pop() : null);
  }, [location]);

  function logout() {
    sessionStorage.removeItem('user');
    auth.signOut().then(() => window.location.assign('/login'));
  }

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        { projectId && <Typography type='h1' width='100%' align='center' fontWeight='bold'> PROJECT - {projectId} </Typography> }
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
  </IconButton> */}
          <IconButton onClick={() => logout()} color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
