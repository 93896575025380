import { Helmet } from 'react-helmet';
import React, { useRef } from 'react';
import {
  CardContent,
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
  TextField,
  Typography,
  IconButton,
  Modal,
} from '@material-ui/core';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import { isMobile } from 'react-device-detect';
import { Document, Page, pdfjs } from 'react-pdf';
import auth from '../firebase';
import '../common/loadingStyle.css';
import 'react-image-lightbox/style.css';
import StaticCalendar from '../components/projects/calendar';
class Project extends React.Component {
  constructor(props) {
    super(props);
    console.log(pdfjs.version);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      projectId: '',
      reports: [],
      activeReportIndex: 0,
      reportDates: [],
      projectInfo: {},
      projectDialog: false,
      x: 0,
      y: 0,
      pdfUrl: '',
      numPages: null,
      pageNumber: 1,
      screenHeight: 0,
      position: 1,
      loaded: false,
      selectedDate: new Date(),
      imageUrls: [],
      imageUrlsExtra: [],
      isOpen: false,
      zoomedImage: '',
    };
  }

  handleShowDialog(image) {
    console.log('heirijre');
    this.setState({ isOpen: !this.state.isOpen, zoomedImage: image });
  };

  componentDidMount() {
    // Get project ID from url
    const splitted = window.location.pathname.split('project/');
    this.setState({ projectId: splitted[1], screenHeight: window.innerHeight });
    this.getProjectInfoAndReports(splitted[1]);
  }
  /**
   * Get project info and use info to get users
   *
   * @param {string} projectId - project id
   *
   * */
  getProjectInfoAndReports(projectId) {
    Promise.all([
      auth.app.firestore()
        .collection('projects')
        .doc(projectId)
        .get(),
      auth.app.firestore()
        .collection('reports')
        .where('signed', '==', true)
        .where('project', '==', projectId)
        .orderBy('date', 'desc')
        .get()
    ]).then(async ([projectDoc, reportDocs]) => {
      const projectData = projectDoc.data();
      const reports = reportDocs?.docs?.map((doc) => doc?.data());
      if (projectData) {
        this.setState({
          projectInfo: {
            ...projectData,
            createdAt: projectData.createdAt.seconds ? moment(projectData.createdAt.toDate()).format('DD/MM/YYYY') : null,
            reportdates: null,
          },
        });
      }
      if (reports) {
        const pdfUrl = reports[0]?.pdf ? await auth.app.storage().ref(reports[0].pdf).getDownloadURL() : '';
        this.getImages(reports[0]);
        this.setState({
          reports,
          reportDates: reports.map((rep) => rep.date.seconds ? rep.date.toDate() : new Date()),
          activeReportIndex: 0,
          pdfUrl,
          selectedDate: new Date(reports[0]?.date?.toDate()) || new Date(),
        });

        setTimeout(() => this.setState({ loaded: true }), 200);
      }
    });
  }

  getImages(report) {
    const newImageUrls = [];
    const promises = [];
    report?.work.forEach((work) => {
      work.images?.forEach((gal, index) => {
        if (gal.length > 0) {
          promises.push(auth.app.storage()
            .ref(gal)
            .getDownloadURL().then((url) => {
              newImageUrls.push(url);
            }));
        }
      });
    });

    report?.extra.images?.forEach((gal, index) => {
      if (gal.length > 0) {
        promises.push(auth.app.storage()
          .ref(gal)
          .getDownloadURL()
          .then((url) => {
            newImageUrls.push(url);
          }));
      }
    });

    Promise.all(promises).then(() => this.setState({ imageUrls: newImageUrls }));
  }

  // Save new project data
  saveProjectData() {
    const {
      newProjectName, newProjectAddress, newProjectEmails, newProjectMissingReportsEmails, projectId, projectInfo
    } = this.state;
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ name: newProjectName, address: newProjectAddress, emails: newProjectEmails.trim().split(','), missingReportEmails: newProjectMissingReportsEmails.trim().split(',') })
      .then(() => {
        projectInfo.name = newProjectName;
        projectInfo.addresss = newProjectAddress;
        projectInfo.emails = newProjectEmails.split(',');
        projectInfo.missingReportEmails = newProjectMissingReportsEmails.split(',');
        this.setState({ projectDialog: false, projectInfo });
      });
  }

  /**
   * Download file
   *
   * @param {string} file - File ref id to download
   *
   * */
  // eslint-disable-next-line class-methods-use-this
  downloadPdf() {
    const { pdfUrl, reports, activeReportIndex } = this.state;
    const pdf = reports[activeReportIndex]?.pdf;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${pdf}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  async changeDate(selected) {
    const { reports } = this.state;
    const report = reports.find((r) => {
      const reportDate = new Date(r.date?.toDate());
      return reportDate.getFullYear() === selected.getFullYear() &&
        reportDate.getMonth() === selected.getMonth() &&
        reportDate.getDate() === selected.getDate()
    });
    const pdfUrl = report?.pdf ? await auth.app.storage().ref(report.pdf).getDownloadURL() : '';
    this.getImages(report);
    this.setState({
      selectedDate: selected,
      pdfUrl,
    });
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const {
      projectId, reports, reportDates, projectInfo, projectDialog, newProjectAddress, newProjectName, newProjectEmails, newProjectMissingReportsEmails,
      pdfUrl, pageNumber, numPages, screenHeight, loaded, selectedDate
    } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Project
            {' '}
            {projectId}
            {' '}
            | Elan daily reporting
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              sx={{ py: 3, px: 3 }}
              spacing={3}
            >
              <Grid
                container
                lg={6}
                sm={6}
                xl={6}
                xs={12}
                sx={{ px: isMobile ? 0 : 3 }}
              >
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  sx={{ height: '100%' }}
                >
                  <Card sx={{ height: '100%' }}>
                    {pdfUrl?.length > 0 && loaded ?
                      <CardContent sx={{ heigth: '100%' }}>
                        <Document
                          options={{ workerSrc: 'pdf.worker.js' }}
                          file={pdfUrl}
                          onLoadError={console.error}
                          onLoadSuccess={(e) => this.onDocumentLoadSuccess(e)}
                        >
                          <Page height={isMobile ? screenHeight * 0.56 : screenHeight * 0.76} pageNumber={pageNumber} />
                        </Document>
                        {pdfUrl?.length > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: 'lightgrey', color: 'black' }}
                            onClick={() => this.setState({ pageNumber: pageNumber > 1 ? (pageNumber - 1) : pageNumber })}
                          >
                            {t('jobsAndProjects.previous')}
                          </Button>
                          <Button
                            sx={{ marginLeft: 2 }}
                            style={{ backgroundColor: 'lightgrey', color: 'black' }}
                            variant="contained"
                            onClick={() => this.setState({ pageNumber: pageNumber < numPages ? (pageNumber + 1) : pageNumber })}
                          >
                            {t('jobsAndProjects.next')}
                          </Button>
                          <Button
                            sx={{ marginLeft: 2 }}
                            variant="contained"
                            onClick={() => this.downloadPdf()}
                          >
                            {t('jobsAndProjects.download')}
                          </Button>
                          <span style={{ marginLeft: 150 }}>
                            {t('jobsAndProjects.page')}
                            {' '}
                            {pageNumber}
                            {' '}
                            {t('jobsAndProjects.of')}
                            {' '}
                            {numPages}
                          </span>
                        </div>}
                      </CardContent>
                      : loaded ? <CardContent sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          Geen rapport op deze dag
                        </Typography>
                      </CardContent> : null}
                  </Card>
                </Grid>
              </Grid>
              <Grid
                container
                lg={6}
                sm={6}
                xl={6}
                xs={12}
                direction={"row"}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    style={{ cursor: 'pointer' }}
                  >
                    <Card onClick={() => (window.location.assign(`settings/${projectId}`))}>
                      <CardHeader
                        title={t('jobsAndProjects.projectDetailsAndUsers')}
                        titleTypographyProps={{ variant:'h4' }}
                        action={
                          <SettingsIcon fontSize="large" style={{ marginRight: 15, marginTop: 5, marginBottom: 5 }} />
                        }
                      />

                    </Card>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    {reportDates &&
                      <StaticCalendar highlightedDates={reportDates} selectedDate={selectedDate} handler={this.changeDate.bind(this)} />}
                  </Grid>
                </Grid>
                <Grid
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  direction={"row"}
                  sx={{ marginTop: 3 }}
                >
                  <Card>
                    <CardHeader
                      title={'Gallery'}
                    />
                    <ImageList cols={3} variant="quilted" rowHeight={164} sx={{ marginLeft: 2, marginRight: 2, marginBottom: 2 }}>
                      {this.state.imageUrls.map((item, index) => (
                        <ImageListItem key={`image-${index}`}>
                          <img
                            src={`${item}`}
                            srcSet={`${item}`}
                            alt={`image-${index}`}
                            height={164}
                            loading="lazy"
                            onClick={() => this.handleShowDialog(item)}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Modal
              open={this.state.isOpen}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              onClick={() => this.handleShowDialog('')}
            >
              <img
                style={{
                  maxHeight: window?.innerHeight * 0.8 || 500,
                  alignSelf: 'center'
                }}
                src={this.state.zoomedImage}
                onClick={() => this.handleShowDialog('')}
                alt="no image"
              />
            </Modal>
          </Container>
          <Dialog fullWidth open={projectDialog} onClose={() => this.setState({ projectDialog: false })} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><Typography fontSize={20} fontWeight="500">{t('common.editDetails')}</Typography></DialogTitle>
            <DialogContent>
              <TextField
                sx={{ marginBottom: 3, marginTop: 2 }}
                fullWidth
                label={t('jobsAndProjects.projectName')}
                name="projectname"
                onChange={(event) => this.setState({ newProjectName: event.target.value })}
                value={newProjectName}
                variant="outlined"
              />
              <TextField
                fullWidth
                label={t('common.address')}
                name="address"
                onChange={(event) => this.setState({ newProjectAddress: event.target.value })}
                value={newProjectAddress}
                variant="outlined"
              />
              <TextField
                sx={{ marginTop: 3 }}
                fullWidth
                label={t('jobsAndProjects.mailList')}
                name="emails"
                onChange={(event) => this.setState({ newProjectEmails: event.target.value })}
                value={newProjectEmails}
                variant="outlined"
              />
              <TextField
                sx={{ marginTop: 3 }}
                fullWidth
                label={t('jobsAndProjects.missingReportMailList')}
                name="Missing report emails"
                onChange={(event) => this.setState({ newProjectMissingReportsEmails: event.target.value })}
                value={newProjectMissingReportsEmails}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ projectDialog: false })} color="primary">
                {t('common.cancel')}
              </Button>
              <Button onClick={() => this.saveProjectData()} color="primary">
                {t('common.save')}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </>
    );
  }
}

// Connect global state and translation to class component
export default withTranslation()(Project);
