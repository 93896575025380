import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  IconButton,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Clear';
import PerfectScrollbar from 'react-perfect-scrollbar';
import auth from '../firebase';
import '../common/loadingStyle.css';
import 'react-image-lightbox/style.css';

class ProjectSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: '',
      projectInfo: {},
      projectDialog: false,
      newProjectName: '',
      newProjectAddress: '',
      newProjectEmails: '',
      newProjectMissingReportsEmails: '',
      loaded: false,
      userDialog: false,
      newUserValue: null,
      allUsers: [],
      searchValue: '',
      users: [],
    };
  }

  componentDidMount() {
    const splitted = window.location.pathname.split('project/settings/');
    this.setState({ projectId: splitted[1] });
    this.getProjectInfoAndUsers(splitted[1]);
  }

  getProjectInfoAndUsers(projectId) {
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .get()
      .then((projectDoc) => {
        const projectData = projectDoc.data();
        if (projectData) {
          this.setState({
            projectInfo: {
              ...projectData,
              createdAt: projectData.createdAt.seconds ? moment(projectData.createdAt.toDate()).format('DD/MM/YYYY') : null,
            },
          });

          if (projectData.users) {
            this.getUsers(projectData.users);
          }
        }
      });
  }

  saveProjectData() {
    const { newProjectName, newProjectAddress, newProjectEmails, newProjectMissingReportsEmails, projectId, projectInfo } = this.state;
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({
        name: newProjectName,
        address: newProjectAddress,
        emails: newProjectEmails.trim().split(','),
        missingReportEmails: newProjectMissingReportsEmails.trim().split(','),
      })
      .then(() => {
        projectInfo.name = newProjectName;
        projectInfo.address = newProjectAddress;
        projectInfo.emails = newProjectEmails.split(',');
        projectInfo.missingReportEmails = newProjectMissingReportsEmails.split(',');
        this.setState({ projectDialog: false, projectInfo });
      });
  }

  async loadUsers() {
    await auth.app.firestore()
      .collection('users')
      .get().then((snap) => {
        const newUsers = [];
        snap.docs.forEach((doc) => {
          newUsers.push({ ...doc.data(), ...{ id: doc.id } });
        });
        this.setState({
          allUsers: newUsers,
          userDialog: true,
          newUserValue: null,
          searchValue: '',
        });
      });
  }

  /**
   * Get project users
   *
   * @param {Array} users - Array of user uid's
   *
   * */
  getUsers(users) {
    const newUsers = [];
    const promises = [];
    users.forEach((uid) => {
      promises.push(auth.app.firestore()
        .collection('users')
        .doc(uid)
        .get()
        .then((snapshot) => {
          newUsers.push({ ...snapshot.data(), ...{ id: snapshot.id } });
        }));
    });
    Promise.all(promises).then(() => this.setState({ users: newUsers }));
  }

  /**
   * Add user to project
   * - Update project users
   * - Update user's projects
   *
   * */
  addUser() {
    const {
      newUserValue, allUsers, projectId, users, projectInfo
    } = this.state;
    const newUser = allUsers.find((u) => u.name === newUserValue);
    const newUsers = users;
    newUsers.push(newUser);
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ users: newUsers.map((usr) => usr.id) })
      .then(() => {
        const newProj = newUser.projects || [];
        newProj.push(projectId);
        auth.app.firestore()
          .collection('users')
          .doc(newUser.id)
          .update({ projects: newProj });
      });
    this.setState({ userDialog: false, users: newUsers });
  }

  /**
   * Delete user from project
   * - Update project users
   * - Update user's projects
   *
   * @param {object} user - User that is deleted from the project
   *
   * */
  deleteUser(user) {
    const { projectId, users } = this.state;
    const newUsers = users.filter((u) => u.id !== user.id);
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ users: newUsers.map((usr) => usr.id) })
      .then(() => {
        const newProj = user.projects?.filter((p) => p !== projectId) || [];
        auth.app.firestore()
          .collection('users')
          .doc(user.id)
          .update({ projects: newProj });
      });
    this.setState({ users: newUsers });
  }


  render() {
    const { t } = this.props;
    const { users, allUsers, searchValue, newUserValue, projectId, projectInfo, projectDialog, newProjectAddress, newProjectName, newProjectEmails, newProjectMissingReportsEmails, userDialog } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Project {projectId} | Elan daily reporting
          </title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Grid item lg={6} sm={6} xl={6} xs={12}>
                <Card>
                  <CardHeader
                    title={`Project ${t('common.details')}`}
                    action={
                      <IconButton
                        onClick={() =>
                          this.setState({
                            projectDialog: true,
                            newProjectName: projectInfo.name,
                            newProjectAddress: projectInfo.address,
                            newProjectEmails: projectInfo.emails.join(','),
                            newProjectMissingReportsEmails: projectInfo.missingReportEmails.join(','),
                          })
                        }
                        aria-label="settings"
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  />
                  <Divider />
                  <PerfectScrollbar>
                    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%' }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell><b>{t('jobsAndProjects.projectName')}</b></TableCell>
                            <TableCell>{projectInfo.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>{t('jobsAndProjects.projectNumber')}</b></TableCell>
                            <TableCell>{projectId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>{t('common.created')}</b></TableCell>
                            <TableCell>{projectInfo.createdAt}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>{t('common.address')}</b></TableCell>
                            <TableCell>{projectInfo.address}{projectInfo.country && `, ${projectInfo.country}`}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>{t('jobsAndProjects.mailList')}</b></TableCell>
                            <TableCell>{projectInfo.emails?.join(', ')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>{t('jobsAndProjects.missingReportMailList')}</b></TableCell>
                            <TableCell>{projectInfo.missingReportEmails?.join(', ')}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>
              <Grid item lg={6} sm={6} xl={6} xs={12}>
                <Card>
                  <CardHeader
                    title={`Project ${t('common.users')}`}
                    action={
                      <IconButton
                        onClick={() => this.loadUsers()}
                        aria-label="settings"
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    }
                  />
                  <Divider />
                  <PerfectScrollbar>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Table>
                        <TableBody>
                          {users.map((customer, index) => (
                            <TableRow
                              hover
                              key={customer.id}
                              selected={users.indexOf(customer.id) !== -1}
                            >
                              <TableCell onClick={() => null}>
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <Typography
                                    color="textPrimary"
                                    variant="body2"
                                  >
                                    {customer.name}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ textAlign: 'right', paddingRight: 2.5 }}>
                                <IconButton sx={{ color: 'black', height: 35, width: 35 }} onClick={() => this.deleteUser(customer)}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>
            </Grid>
          </Container>

          <Dialog fullWidth open={projectDialog} onClose={() => this.setState({ projectDialog: false })}>
            <DialogTitle>{t('common.editDetails')}</DialogTitle>
            <DialogContent>
              <TextField
                sx={{ marginBottom: 3, marginTop: 2 }}
                fullWidth
                label={t('jobsAndProjects.projectName')}
                onChange={(event) => this.setState({ newProjectName: event.target.value })}
                value={newProjectName}
                variant="outlined"
              />
              <TextField
                fullWidth
                label={t('common.address')}
                onChange={(event) => this.setState({ newProjectAddress: event.target.value })}
                value={newProjectAddress}
                variant="outlined"
              />
              <TextField
                sx={{ marginTop: 3 }}
                fullWidth
                label={t('jobsAndProjects.mailList')}
                onChange={(event) => this.setState({ newProjectEmails: event.target.value })}
                value={newProjectEmails}
                variant="outlined"
              />
              <TextField
                sx={{ marginTop: 3 }}
                fullWidth
                label={t('jobsAndProjects.missingReportMailList')}
                onChange={(event) => this.setState({ newProjectMissingReportsEmails: event.target.value })}
                value={newProjectMissingReportsEmails}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ projectDialog: false })} color="primary">
                {t('common.cancel')}
              </Button>
              <Button onClick={() => this.saveProjectData()} color="primary">
                {t('common.save')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth open={userDialog} onClose={() => this.setState({ userDialog: false })} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><Typography fontSize={20} fontWeight="500">{t('users.add')}</Typography></DialogTitle>
            <DialogContent>
              <Autocomplete
                freeSolo
                id="name"
                disableClearable
                value={newUserValue}
                onChange={(_, newValue) => {
                  this.setState({ newUserValue: newValue });
                }}
                inputValue={searchValue}
                onInputChange={(_, newInputValue) => {
                  this.setState({ searchValue: newInputValue });
                }}
                options={allUsers.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('users.search')}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ userDialog: false })} color="primary">
                {t('common.cancel')}
              </Button>
              <Button onClick={() => this.addUser()} color="primary">
                {t('common.add')}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </>
    );
  }
}

export default withTranslation()(ProjectSettings);
